export const LEVELS = {
  LOCATION: 'location',
  PARTNER: 'partner',
  GROUP: 'group',
  REGION: 'region',
};

export const PLAN_FREQUENCIES = {
  annually: 'Annually',
  halfyearly: 'Every 6 Months',
  quarterly: 'Every 3 Months',
  monthly: 'Monthly',
  // weekly: "Weekly",
};

export const EXT_PLAN_FREQUENCIES = {
  annually: 'Annually',
  halfyearly: 'Every 6 Months',
  four_months: 'Every 4 Months',
  quarterly: 'Every 3 Months',
  monthly: 'Monthly',
  // weekly: "Weekly",
  unlimited: 'Unlimited',
};

export const FREQUENCY_MAPPER = {
  annually: 0,
  halfyearly: 6,
  four_months: 4,
  quarterly: 3,
  monthly: 1,
  weekly: 0,
};

export const FEE_SCHEDULE_MAPPER = {
  ucr_fees_schedule: 'UCR Fees Schedule',
};

export const SUBSCRIPTION_TYPE = {
  CUSTOMER: {
    name: 'Subscriber',
    value: 'Customer',
  },
  EMPLOYEE: {
    name: 'Employee',
    value: 'Employee',
  },
};

export const PLAN_ATTRIBUTES = {
  addon_allowed: 'Allow Add-ons',
  private_only: 'Visible In-Office Only',
  Customer: 'Member Type: Subscriber',
  Employee: 'Member Type: Employee',
  allow_future_dated: 'Allow Future Date',
};

export const CAROUSEL_RESPONSIVE = {
  extraSuperLargeDesktop: {
    breakpoint: { max: 4000, min: 1800 },
    items: 4,
  },
  superLargeDesktop: {
    breakpoint: { max: 1800, min: 1400 },
    items: 3,
  },
  largeDesktop: {
    breakpoint: { max: 1400, min: 1200 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

// Default Stripe Charges
export const REACT_APP_STRIPE_CHARGE_PERCENT = 2.9;
export const REACT_APP_STRIPE_CHARGE_FIXED_CENTS = 31;

// Credit Card Dynamic Charges
export const REACT_APP_CREDIT_CHARGE_PERCENT = 2.2;
export const REACT_APP_CREDIT_CHARGE_FIXED_CENTS = 30;

// Debit Card Dynamic Charges
export const REACT_APP_DEBIT_CHARGE_PERCENT = 1;
export const REACT_APP_DEBIT_CHARGE_FIXED_CENTS = 30;
