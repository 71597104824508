import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import styles from './SectionHeader.module.scss';

const SectionHeader = (props) => {
  const {
    title,
    titleVariant,
    titleComponent,
    titleColor = '',
    titleClasses = '',
    helperText,
    titleJustify,
    subTitle,
    rightSection,
    gutterBottom,
    className,
    subTitleProps = {},
    dataLoading = false,
  } = props;

  return (
    <div className={clsx({ [styles.gutterBottom]: gutterBottom }, className)}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent={titleJustify}
      >
        <Grid item>
          <Typography
            variant={titleVariant}
            component={titleComponent}
            color={titleColor}
            className={clsx(...titleClasses, 'd-flex', 'align-center')}
          >
            {title}
            {helperText && (
              <Grid item className={styles.helperTextalignment}>
                {helperText}
              </Grid>
            )}
            {dataLoading && (
              <CircularProgress size={20} color="primary" className="ml-10" />
            )}
          </Typography>
        </Grid>

        {rightSection && <Grid item>{rightSection}</Grid>}
      </Grid>

      {subTitle && (
        <Grid container spacing={0}>
          <Grid item>
            <Typography
              variant="h6"
              component="h6"
              color="textSecondary"
              className={styles.subTitle}
              {...subTitleProps}
            >
              {subTitle}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

SectionHeader.defaultProps = {
  titleVariant: 'h1',
  titleComponent: 'h1',
  titleColor: 'textPrimary',
  titleJustify: 'space-between',
};

SectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  titleClasses: PropTypes.string,
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
    .isRequired,
  titleComponent: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
    .isRequired,
  titleColor: PropTypes.string,
  titleJustify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'auto',
  ]),
  subTitleProps: PropTypes.object,
  helperText: PropTypes.node,
  rightSection: PropTypes.node,
  gutterBottom: PropTypes.bool,
  className: PropTypes.string,
  dataLoading: PropTypes.bool,
};

export default SectionHeader;
