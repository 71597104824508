import React, { useContext, useState, useRef } from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import {
  ColorLens as ColorLensIcon,
  ExitToApp as ExitToAppIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  Tune as TuneIcon
} from '@material-ui/icons';
import { Autocomplete } from "@material-ui/lab";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router";
import Avatar from 'react-avatar';

import termsIcon from '../../assets/images/termsIcon.svg'
import {
  PARTNER_ADMIN,
  GROUP_ADMIN,
  REGION_ADMIN,
  LOCATION_ADMIN,
} from "global-constants/roles";
import { Context } from 'context';
import { LOGOUT, SUBSCRIBERS_FILTERS_UPDATED } from 'context/actions';
import { RoleValidator } from 'components';
import { ThemeChangeModal } from './components';
import FilterModal from "Views/Common/Custom/FilterModal";
// import Logo from 'assets/images/logo.svg';
import OutsideClick from "Views/Common/OutsideClick";
import { GLOBAL_SEARCH_SUGGESTIONS, GLOBAL_SEARCH_MATCHES } from "Views/Common/enum";
import Utils from "Shared/Utils";
import styles from './Header.module.scss';
import './styles.scss';

const Header = () => {
  const {
    state: {
      user = {},
      domainConfig: { logo, logoAlt = "logo" },
      subscriberFilters,
    },
    dispatch,
  } = useContext(Context);
  const { filterState: customFilter } = subscriberFilters;

  const [isOpen, setIsOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [termsAnchorEl, setTermsAnchorEl] = useState(null);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [updateTheme, setThemeUpdate] = useState(false);

  const [searchType, setSearchType] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const termsMenu = [
    {
      title: "Terms of Service",
      linkTo: "/terms-of-service",
    },
    {
      title: "Privacy Policy",
      linkTo: "/privacy-policy",
    },
    {
      title: "HIPAA Notice",
      linkTo: "/hipaa-notice",
    },
  ];

  const ref = useRef();
  const history = useHistory();

  OutsideClick(ref, () => {
    setIsOpen(false);
  });

  const onLogout = () => dispatch({ type: LOGOUT });

  const onClickAccountSetting = () => {
    history.push({
      pathname: `/settings`
    });

    setIsOpen(!isOpen);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleTermsClose = () => {
    setIsTermsOpen(false);
    setTermsAnchorEl(null);
  };

  const handleMenuItemClick = (event) => {
    if (!isOpen) {
      setAnchorEl(event.currentTarget);
    }

    setIsOpen(!isOpen);
  };

  const handleTermsClick = (event) => {
    if(!isTermsOpen) {
      setTermsAnchorEl(event.currentTarget)
    }

    setIsTermsOpen(!isTermsOpen)
  };

  const openFilters = () => setFilterOpen(true);

  const changeTheme = () => setThemeUpdate(true);

  const handleSearchTextClick = () => {
    if(!searchValue.length) return;

    if (history.location.pathname !== '/subscriber/list')
      history.push('/subscriber/list');

    let filterObj = {
      key: 'text',
      value: searchValue
    };

    if(Object.keys(searchType).length) {
      let searchVal = searchValue.split(':')?.[1]?.trim();

      filterObj = {
        key: searchType?.key,
        value: searchVal,
      };
    } else if (searchValue.indexOf(':') !== -1) {
      const searchArray = searchValue.split(':');
      const searchKey = searchArray?.[0]?.trim().toLowerCase().replace(/ /g, '');
      const searchVal = searchArray?.[1]?.trim();

      const sType = searchKey && GLOBAL_SEARCH_MATCHES[searchKey];

      if(sType) {
        filterObj = {
          key: sType,
          value: searchVal
        }
      }
      else {
        filterObj.value = searchValue;
      }
    } else if (searchValue && !Number.isNaN(Number(searchValue))) {
      const searchLength = searchValue.length;
      const searchType = GLOBAL_SEARCH_SUGGESTIONS?.find((x) =>
        searchLength >= 0 && searchLength < 10
          ? x.key === 'patient_id'
          : searchLength === 10 && x.key === 'phone',
      );

      if (searchType) {
        filterObj = {
          key: searchType.key,
          value: searchValue,
        };
      }
    }

    if(!filterObj?.value) return;

    const {
      partner_id: pId,
      group_id: gId,
      region_id: rId,
    } = user?.settings?.level_data || {};

    let updatedFilters = {
      partner_id: customFilter?.partner_id || pId || '',
      group_id: customFilter?.group_id || gId || '',
      region_id: rId || ""
    };

    const INTERNAL_USERS = Utils.checkIfInternalUser(user);

    if (INTERNAL_USERS)
      updatedFilters = {
        partner_id: "ALL_PARTNERS",
        group_id: "ALL_GROUPS",
        region_id: ""
      };

    dispatch({
      type: SUBSCRIBERS_FILTERS_UPDATED,
      payload: {
        subscriberFilters: {
          ...subscriberFilters,
          filterState: {
            ...customFilter,
            text: '',
            ...updatedFilters,
            location_id: "",
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            patient_id: "",
            subscription_id: "",
            date_of_birth: "",
            created_start: null,
            created_end: null,
            year: null,
            statStatus: '',
            [filterObj.key]: filterObj.value
          },
          pageProps: {
            page_number: 0,
            rowsPerPage: 100,
          },
          isFilterUpdated: true
        }
      }
    });

    setSearchType({});
    setSearchValue('');

    document.getElementById("search-auto-complete").blur();
  };

  const renderAutoTextField = (params) => {
    const {
      InputProps = {},
      inputProps = {},
      ...restProps
    } = params;

    return (
      <TextField
        fullWidth
        variant="outlined"
        name="search"
        className={styles.globalSearch}
        placeholder="Search Subscribers"
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={openFilters} data-testid="header-open-filter-icon">
                <TuneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          'data-testid': 'search-subscriber-input',
          ...inputProps,
          className: clsx(inputProps?.className, styles.searchTextfield),
        }}
        {...restProps}
      />
    );
  };

  const renderCustomOption = (props) => {
    const {
      key = '',
      value = '',
      example = ''
    } = props;

    return (
      <Grid container key={key} className={styles.searchOptionCont}>
        <Grid item xs={12} sm={4}>
          <span className={styles.searchDescription}> {value} </span>
        </Grid>
        <Grid item xs={12} sm={8} className={styles.searchExample}> {example} </Grid>
      </Grid>
    );
  };

  const renderTermsItems = (label, linkTo) => (
    <MenuItem
      onClick={handleTermsClose}
      data-testid={`header-${label}`}
      key={`header-${label}`}
    >
      <Link
        to={`${linkTo}`}
        className={styles.termItems}
        data-testid={`link-${label}`}
      >
        {label}
      </Link>
    </MenuItem>
  );

  const renderTermsMenu = () => {
    return (
      <>
        <IconButton
          onClick={handleTermsClick}
          className={styles.termsHeaderButton}
          disableRipple={true}
          data-testid="header-terms-button"
        >
          <img src={termsIcon} alt="Terms" />
        </IconButton>
        <Menu
          anchorEl={termsAnchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={isTermsOpen}
          onClose={handleTermsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {termsMenu.map((terms) =>
            renderTermsItems(terms?.title, terms?.linkTo)
          )}
        </Menu>
      </>
    );
  };

  return (
    <AppBar className={clsx(styles.header, styles.primaryAppBar, 'header-appbar')} elevation={0}>
      <Grid container spacing={1} alignItems="center">
        <Grid item >
          <Link to="/dashboard" data-testid="header-dashboard-link">
            <img src={logo} alt={logoAlt} className={styles.logo} />
          </Link>
        </Grid>

        <Grid item className={clsx("flex-grow flex-shrink", styles.searchWrapper)}>
          <Hidden xsDown>
            <Autocomplete
              id="search-auto-complete"
              size="small"
              name="header-autocomplte"
              className={styles.globalAutoComplete}
              options={GLOBAL_SEARCH_SUGGESTIONS}
              renderOption={renderCustomOption}
              getOptionLabel={(option) => option.value || ''}
              groupBy={(option) => option.groupKey || ''}
              renderInput={renderAutoTextField}
              value={searchType}
              onChange={(evt, newValue) => newValue && setSearchType(newValue)}
              inputValue={searchValue}
              onInputChange={(evt, newInputValue) => setSearchValue(newInputValue)}
              onKeyDown={(evt) => (evt.keyCode === 13) && handleSearchTextClick()}
            />
          </Hidden>
        </Grid>
        <Grid item className={styles.termsContainer}>
         {renderTermsMenu()}
        </Grid>
        <Grid item>
          <Button
            onClick={handleMenuItemClick}
            startIcon={
              <Avatar name={user?.name || ''}
                size="40"
                round={true}
                color={`
                  linear-gradient(95.69deg, 
                  var(--theme-highlight-color) 4.34%, 
                  var(--theme-highlight-color-bg) 55.62%, 
                  var(--theme-highlight-color-2) 91.79%)
                `}
              />
            }
            data-testid="header-avatar-button"
          >
            <div className="d-flex flex-column">
              <Typography variant="body2" align="left" className={styles.userName}>
                {user?.name || ''}
              </Typography>

              <Typography variant="caption" align="left">
                {user && user['custom:role'] ? Utils.formatUserRole(user['custom:role']) : ''}
              </Typography>
            </div>
          </Button>

          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            open={isOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <RoleValidator roles={[
              PARTNER_ADMIN,
              GROUP_ADMIN,
              REGION_ADMIN,
              LOCATION_ADMIN,
            ]}>
              <MenuItem onClick={onClickAccountSetting} data-testid="header-account-settings">
                <ListItemIcon>
                  <SettingsIcon className='menuIcons' />
                  <Typography variant="body1">Account Settings</Typography>
                </ListItemIcon>
              </MenuItem>
            </RoleValidator>

            <MenuItem onClick={changeTheme} data-testid="header-change-theme">
              <ListItemIcon>
                <ColorLensIcon className='menuIcons' />
                <Typography variant="body1">Change Theme</Typography>
              </ListItemIcon>
            </MenuItem>

            <MenuItem onClick={onLogout} data-testid="header-logout">
              <ListItemIcon>
                <ExitToAppIcon className='menuIcons' />
                <Typography variant="body1">Logout</Typography>
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {isFilterOpen && (
        <FilterModal
          isFilterOpen={isFilterOpen}
          closeFilterModal={() => setFilterOpen(false)}
        />
      )}

      <ThemeChangeModal isOpen={updateTheme} onClose={() => setThemeUpdate(false)} />
    </AppBar>
  )
};

export default Header;
