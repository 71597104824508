import React, { useContext, lazy, Suspense, useEffect, useState, useMemo } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

import CommonLoader from "../Views/Common/CommonLoader"
import { Context } from 'context';
import { AUTHENTICATION_COMPLETE, DOMAIN_CONFIG_UPDATED, FRESHCHAT_INIT } from 'context/actions';
import getDomainConfig from "config/domain-config";
import getTheme from "theme";
import Main from "./Main/Main";
import GlobalNotification from 'common-layout/GlobalNotification';
import { roleSpecificUserData } from "Views/Login/LoginHelper";
import Utils from "Shared/Utils";
import { LOCATION_SERVICES } from "Services";

// lazy loading components.
const LoginComponent = lazy(() => import('../Views/Login/LoginComponent/LoginComponent'));
const ForgotPassword = lazy(() => import("../Views/ForgotPassword/ForgotPassword"));

const { REACT_APP_VYNE_MARKETING_PAGE = 'https://vynedental.com/plans/' } = process.env;

const Routes = () => {
  const {
    state: {
      user,
      isUserValidating,
      freshchat_initialised = false,
      activeTheme,
    },
    dispatch,
  } = useContext(Context);

  const [theme, setTheme] = useState(getTheme(activeTheme));
  const [initCount, setInitCount] = useState(0);

  const { activeTheme: domainTheme, ...config } = getDomainConfig();

  const { pathname, search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const initFreshChat = () => {
    if (!window.fcWidget) {
      setInitCount(prevVal => prevVal+1);
      return;
    }

    dispatch({
      type: FRESHCHAT_INIT,
    });

    window.fcWidget.init({
      token: "e487c9b8-34c6-4bf8-bc22-0f026f05d7ec",
      host: "https://subscribili-team.freshchat.com",
      externalId: user?.email,
      restoreId: user?.restoreId || null,
    });

    window.fcWidget.on("widget:loaded", function () {
      window.fcWidget.user.get(function (resp) {
        if (resp?.status !== 200) {
          const {
            name,
            email,
            phone_number,
            settings = {}
          } = user;

          const fullName = name.trim().split(' ');
          const [firstName = '', lastName = ''] = fullName;
          const role = user['custom:role'] ? Utils.formatUserRole(user['custom:role']) : '';
          const level_name = settings?.level_data?.[`${user?.level}_name`] || '';

          window.fcWidget.user
            .create({
              firstName,
              lastName,
              email,
              phone: phone_number.slice(-10),
              phoneCountryCode: phone_number.slice(0, 2),
              meta: {
                role,
                level_name,
              }
            });

          window.fcWidget.on("user:created", function (resp) {
            let data = resp?.data;
            if (resp?.status === 200) {
              if (data.restoreId) {
                // TODO - Add this id to user db
              }
            }
          });
        }
      });
    });
  };

  const MAX_RETRIES = 10;
  useEffect(() => {
    let timeoutId = '';

    if(user) {
      if (freshchat_initialised || initCount >= MAX_RETRIES)
        clearTimeout(timeoutId);
      else
        timeoutId = setTimeout(() => {
          initFreshChat();
        }, 500*2);
    }
  }, [user, initCount, freshchat_initialised]);

  // useEffect(() => {
  //   if (typeof (window.FreshworksWidget) === "function") {
  //     user
  //       ? window.FreshworksWidget("show")
  //       : window.FreshworksWidget("hide");
  //   }
  // }, [pathname, user]);

  const fetchLocationByFacilityId = async (id) => {
    if (!id) return;

    try {
      const response = await LOCATION_SERVICES.getLocationByFacilityId(id);

      if (response?.type === 'success') {
        dispatch({
          type: AUTHENTICATION_COMPLETE,
          payload: { user: null }
        });
      }
      else {
        throw response;
      }
    }
    catch (err) {
      console.log(err);

      window.location = REACT_APP_VYNE_MARKETING_PAGE;
    }
  };

  useEffect(() => {
    const userDetails = localStorage.getItem('userDetail');

    if (userDetails) {
      const getUserDetails = async () => {
        const updatedDetails = await roleSpecificUserData(JSON.parse(userDetails));

        dispatch({
          type: AUTHENTICATION_COMPLETE,
          payload: { user: updatedDetails }
        });
      };

      getUserDetails();
    }
    else {
      const noPath = pathname === "/";
      const facilityId = searchParams.get('facid');

      if (noPath && facilityId) {
        fetchLocationByFacilityId(facilityId);
      }
      else {
        dispatch({
          type: AUTHENTICATION_COMPLETE,
          payload: { user: null }
        });
      }

      (async () => {
        await signOut();
      })();
    }

    dispatch({
      type: DOMAIN_CONFIG_UPDATED,
      payload: { domainConfig: config, activeTheme: domainTheme }
    });
  }, []);

  useEffect(() => {
    setTheme(getTheme(activeTheme));
  }, [activeTheme]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<CommonLoader></CommonLoader>}>
        <Switch>
          {user && <Route path="/" component={Main} />}

          <Route path="/forgot-password" exact component={ForgotPassword} />

          {!user && <Route path="/login" exact component={LoginComponent} />}
          {!user && !isUserValidating && <Redirect to={'/login'} />}
        </Switch>
      </Suspense>
      <GlobalNotification />
      <CssBaseline />
    </ThemeProvider>
  )
};

export default Routes;
