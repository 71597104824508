import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Grid,
  FormHelperText,
} from '@material-ui/core';

// Components
import { Select } from 'components';

const VENDOR_NAME = {
  jotform: 'Jotform',
};

const PatientConsent = (props) => {
  const {
    showForm = false,
    isLoading,
    data: vendorsList = [],
    consentVendorName,
    onConsentVendorSelect,
    consentConfigName,
    onConfigNameChange,
    reqFields,
    disabled = false,
    children,
  } = props;

  if (!showForm) return <> {children} </>;

  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid item xs={12} lg={4} className="d-flex">
        <FormControl
          fullWidth
          required
          variant="outlined"
          disabled={isLoading || disabled}
          error={reqFields?.['vendor']?.error}
        >
          <InputLabel htmlFor="outlined-Group-native-simple">
            Vendors
          </InputLabel>
          <Select
            id="consent-select-vendor"
            label="Vendors*"
            name="vendor"
            value={consentVendorName}
            onChange={onConsentVendorSelect}
          >
            {vendorsList?.map((vendor, i) => (
              <MenuItem
                value={vendor}
                key={`${i}_${vendor}`}
                data-testid={`consent-vendor-select-item-${i}`}
              >
                {VENDOR_NAME?.[vendor]}
              </MenuItem>
            ))}
          </Select>
          {reqFields?.['vendor']?.text && reqFields?.['vendor']?.error && (
            <FormHelperText className="color_error">
              {reqFields?.['vendor']?.text}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} lg={4} className="d-flex">
        <TextField
          fullWidth
          required
          name="config_name"
          label="Config Name"
          value={consentConfigName}
          variant="outlined"
          placeholder="Config Name"
          onChange={onConfigNameChange}
          error={reqFields?.['config_name']?.error}
          helperText={
            reqFields?.['config_name']?.error &&
            reqFields?.['config_name']?.text
          }
          disabled={disabled}
          inputProps={{
            'data-testid': 'config-name-input',
          }}
        />
      </Grid>

      {children}
    </Grid>
  );
};

PatientConsent.propTypes = {
  showForm: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  consentVendorName: PropTypes.string,
  onConsentVendorSelect: PropTypes.func,
  consentConfigName: PropTypes.string,
  onConfigNameChange: PropTypes.func,
  reqFields: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default PatientConsent;
