import React, { useState, useEffect, useMemo, useContext } from "react";
import _ from "lodash";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Grid,
  CircularProgress,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";

import { Select, CheckBox, PhoneInput, DividerWithText } from "components";
import { Context } from "context";
import { ADD_NOTIFICATION, UPDATE_PAYMENT_DETAILS } from "context/actions";
import useResponsiveFontSize from "Views/Common/useResponsiveFontSize";
import { STATES } from "Views/Common/enum";
import { USER_SERVICES } from "Services";
import Utils from "Shared/Utils";
import { formatZipcode } from "utils";
import {
  requiredFieldMessage,
  phoneValidationMessage,
  zipcodeValidationMessage,
} from "utils/validationMessages";
import styles from "./PaymentType.module.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const PaymentType = (props) => {
  const {
    getProviderPayments = true,
    values: {
      group_id,
      partner_id,
      first_name: contact_first_name = '',
      last_name: contact_last_name = '',
      email: contact_email = '',
      phone: contact_phone,
      line1: contact_line1,
      line2: contact_line2,
      city: contact_city,
      state: contact_state,
      zipcode: contact_zipcode,
    } = {},
    defaultPayments = ["Card"],
    showBillingAddress = false,
  } = props;

  const {
    state: { paymentDetails = {} },
    dispatch,
  } = useContext(Context);

  const { values = {}, blurred = {}, errors = {} } = paymentDetails;

  const {
    error,
    payment_type,
    same_address,
    full_name,
    phone,
    line1,
    city,
    state,
    zipcode,
  } = values;

  const [settingsLoading, setSettingsLoading] = useState(false);
  const [providerPaymentTypes, setProviderPaymentTypes] = useState([]);

  const updatePaymentValues = (name, value) => {
    const updatedValues = {
      ...values,
      [name]: value,
    };

    dispatch({
      type: UPDATE_PAYMENT_DETAILS,
      payload: {
        values: updatedValues,
      },
    });
  };

  const updatePaymentType = (value) =>
    updatePaymentValues("payment_type", value);

  const updateSameAddress = () => {
    if (same_address)
      updatePaymentValues("same_address", false);
    else {
      const fullName = (contact_first_name || "") + " " + (contact_last_name || "");
      const addressLine = (contact_line1 || "") + " " + (contact_line2 || "");

      const updatedValues = {
        ...values,
        same_address: true,
        full_name: fullName.trim(),
        email: contact_email,
        phone: contact_phone,
        line1: addressLine.trim(),
        city: contact_city,
        state: contact_state,
        zipcode: contact_zipcode,
      };

      let clonedErrors = _.cloneDeep(errors);

      Object.keys(clonedErrors).forEach(
        (errKey) =>
          (clonedErrors[errKey] = "")
      );
  
      dispatch({
        type: UPDATE_PAYMENT_DETAILS,
        payload: {
          values: updatedValues,
          errors: clonedErrors,
        },
      });
    }
  };

  const handlePaymentClick = (evt) => {
    const { value } = evt?.target || {};

    if (payment_type !== value) updatePaymentType(value);
  };

  const resetPaymentTypes = () => {
    setProviderPaymentTypes([...defaultPayments]);

    if (payment_type !== defaultPayments?.[0])
      updatePaymentType(defaultPayments?.[0] || "");
  };

  const fetchSettingsDetail = async () => {
    if (!group_id) return;

    setSettingsLoading(true);

    try {
      const response = await USER_SERVICES.getSettings(
        "group",
        group_id,
        `partner_id=${partner_id}`
      );

      if (response?.type === "success") {
        const { settings: { provider_payment_types = [] } = {} } =
          response?.data || {};

        if (provider_payment_types.length) {
          let providerPaymentTypes = [...provider_payment_types];

          // NOTE(krs): Removed for now, requires additional work, will be enabled after September
          // if (orderType === "CreateInvoice" || orderType === "UpdateOrderPayment")
          //   providerPaymentTypes.push("ACH");

          setProviderPaymentTypes(providerPaymentTypes);

          if (!provider_payment_types.includes(payment_type))
            updatePaymentType(provider_payment_types[0]);
        } else {
          resetPaymentTypes();
        }
      } else throw response;
    } catch (err) {
      console.log(
        err?.message || err?.error || "Error while fetching settings details"
      );
    }

    setSettingsLoading(false);
  };

  useEffect(() => {
    if (group_id) {
      if (getProviderPayments) fetchSettingsDetail();
      else resetPaymentTypes();
    }
  }, [group_id]);

  const emitValueChange = (props = {}) => {
    const { name, label, value } = props;

    const updatedValues = {
      ...values,
      [name]: value,
    };

    const updatedBlurred = {
      ...blurred,
      [name]: false,
    };

    let updatedErrors = {};

    if (name === "phone") {
      const phoneError = phoneValidationMessage(value);

      updatedErrors = {
        ...errors,
        [name]: phoneError,
      };
    } else if (name === "zipcode") {
      const zipError = zipcodeValidationMessage(value);

      updatedErrors = {
        ...errors,
        [name]: zipError,
      };
    } else {
      updatedErrors = {
        ...errors,
        [name]: requiredFieldMessage(value, label),
      };
    }

    dispatch({
      type: UPDATE_PAYMENT_DETAILS,
      payload: {
        values: updatedValues,
        blurred: updatedBlurred,
        errors: updatedErrors,
      },
    });
  };

  const emitBlurChange = (name, value) => {
    const updatedBlurred = {
      ...blurred,
      [name]: value,
    };

    dispatch({
      type: UPDATE_PAYMENT_DETAILS,
      payload: {
        blurred: updatedBlurred,
      },
    });
  };

  const renderTextField = (name, label, value, required = true, props = {}) => (
    <TextField
      fullWidth
      required={required}
      variant="outlined"
      name={name}
      label={label}
      value={value}
      error={required && blurred?.[name] && Boolean(errors?.[name])}
      helperText={required && blurred?.[name] && errors?.[name]}
      onChange={(evt) =>
        emitValueChange({ name, label, value: evt?.target?.value })
      }
      onBlur={() => emitBlurChange(name, true)}
      inputProps={{
        "data-testid": `order-package-${name}`,
      }}
      disabled={same_address}
      {...props}
    />
  );

  const handlePhoneChange = (name, label, value) =>
    emitValueChange({ name, label, value });

  const renderPhoneInput = (name, label, value) => (
    <FormControl variant="outlined" disabled={same_address} fullWidth>
      <PhoneInput
        id={`order-package-${name}`}
        required
        name={name}
        value={value}
        isValid={blurred[name] && !errors[name]}
        error={blurred[name] && errors[name]}
        defaultErrorMessage={
          blurred?.[name] && errors?.[name] ? errors?.[name] : ""
        }
        onChange={(value) => {
          handlePhoneChange(name, label, value);
        }}
        onBlur={() => emitBlurChange(name, true)}
        disabled={same_address}
      />

      {blurred[name] && errors[name] && (
        <FormHelperText className="color_error">
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderSelectField = (
    name,
    label,
    value,
    DROPDOWN_LIST = [],
    labelKey = "key",
    valueKey = "value"
  ) => (
    <FormControl
      fullWidth
      required
      variant="outlined"
      error={errors[name]}
      disabled={same_address}
    >
      <InputLabel> {`Select ${label}`} </InputLabel>
      <Select
        id={`order-package-select-${name}`}
        label={`Select ${label}*`}
        name={name}
        defaultValue={""}
        value={value}
        onChange={(evt) =>
          emitValueChange({ name, label, value: evt?.target?.value })
        }
      >
        {Boolean(DROPDOWN_LIST.length) &&
          DROPDOWN_LIST.map((indItem, i) => (
            <MenuItem
              key={indItem[labelKey]}
              value={indItem[labelKey]}
              data-testid={`order-package-select-${name}-item-${i}`}
            >
              {indItem[valueKey]}
            </MenuItem>
          ))}
      </Select>
      {errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4} className="position-relative">
        <FormControl
          variant="outlined"
          fullWidth
          disabled={settingsLoading || providerPaymentTypes?.length === 1}
        >
          <InputLabel htmlFor="outlined-type-native-simple">
            Payment Method
          </InputLabel>
          <Select
            id="select-payment-type"
            fullWidth
            value={payment_type}
            label="Payment Method"
            inputProps={{
              name: "payment_type",
              id: "outlined-type-native-simple",
            }}
            onChange={handlePaymentClick}
          >
            {providerPaymentTypes.map((indPayment, pIndex) => {
              return (
                <MenuItem
                  key={indPayment}
                  value={indPayment}
                  data-testid={`payment-plan-select-item-${pIndex}`}
                >
                  {indPayment}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {settingsLoading && (
          <CircularProgress
            size={20}
            thickness={5}
            className="dropdown-loader"
          />
        )}
      </Grid>

      {payment_type === "Card" && (
        <Grid item xs={12} sm={8}>
          <FormControl variant="outlined" fullWidth error={Boolean(error?.message)}>
            <Elements stripe={stripePromise}>
              <CardForm error={Boolean(error?.message)} {...props} />
            </Elements>

            {error?.message && (
              <FormHelperText> {error?.message} </FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}

      {showBillingAddress && payment_type === "Card" && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DividerWithText
                text="Billing Address"
                align="left"
                spacing={"2_5"}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={same_address}
                    onChange={updateSameAddress}
                    name="same_address"
                    inputProps={{
                      "data-testid": `same-billing-address-checkbox`,
                    }}
                  />
                }
                label="Same as contact address"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField("full_name", "Full Name", full_name)}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderPhoneInput("phone", "Phone", phone)}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField("line1", "Address Line", line1)}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField("city", "City", city)}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderSelectField(
                "state",
                "State",
                state,
                STATES,
                "code",
                "name"
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {renderTextField("zipcode", "Zipcode", zipcode, true, {
                type: "number",
                onInput: (e) => {
                  e.target.value = formatZipcode(e.target.value);
                },
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useOptions = (hidePostalCode) => {
  const fontSize = useResponsiveFontSize();

  return useMemo(
    () => ({
      hidePostalCode,
      style: {
        base: {
          color: "#424770",
          "::placeholder": {
            color: "#787697",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );
};

const CardForm = (props) => {
  const {
    stripeIntent,
    invokeSripeConfirm,
    setInvokeSripeConfirm,
    emitCheckoutEvent,
    hidePostalCode = true,
    setLoading,
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions(hidePostalCode);

  const {
    state: { paymentDetails = {} },
    dispatch: globalDispatch,
  } = useContext(Context);
  const { values = {} } = paymentDetails;

  const {
    full_name,
    email,
    phone,
    line1,
    line2,
    city,
    state,
    zipcode,
  } = values;

  useEffect(() => {
    invokeSripeConfirm && handleSubmit();
  }, [invokeSripeConfirm]);

  const setBankErrorMessage = (errorData) => {
    let message = Utils.getStripeError(errorData);

    let notification = { severity: "error", message: message };
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification },
    });
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    try {
      const billing_details = {
        name: full_name,
        email,
        phone,
        address: {
          line1,
          line2,
          city,
          state,
          postal_code: zipcode,
          country: 'US',
        },
      };

      let data = await stripe.confirmCardSetup(stripeIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details
        },
      });

      if (data?.error) {
        Sentry.captureException(data?.error);

        setBankErrorMessage(data?.error);
        setLoading(false);
        setInvokeSripeConfirm(false);
        return;
      }

      emitCheckoutEvent(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <label className={styles.stripeLabel}>
      <CardElement
        options={options}
        onChange={(event) => {
          setInvokeSripeConfirm(false);

          globalDispatch({
            type: UPDATE_PAYMENT_DETAILS,
            payload: {
              values: {
                ...values,
                error: event?.error,
                isValid: event?.complete,
              },
            },
          });
        }}
      />
    </label>
  );
};

export default PaymentType;
