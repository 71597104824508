import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  InputAdornment,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  Save as SaveIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';

// Components
import { SectionWrapper, ToggleTickSwitch, CheckBox } from 'components';

// Services
import { REVSHARE_SERVICES, USER_SERVICES } from 'Services';

// Context
import { Context } from 'context';
import { ADD_NOTIFICATION } from 'context/actions';

// Constants
import { CHARGE_DATE, CHARGE_LEVEL, PAYMENT_TYPES } from 'Views/Common/enum';

// Styles
import styles from './PaymentCapabilities.module.scss';
import useStyles from './styles';
import Utils from 'Shared/Utils';

const PAYOUT_TYPES = [
  {
    label: '$',
    value: 'fixed',
  },
  {
    label: '%',
    value: 'percent',
  },
];

const PaymentCapabilities = (props) => {
  const {
    levelData = {},
    settings: {
      provider_payment_types = [],
      setting_level,
      platform_cost_enabled = false,
      platform_cost_amount = 0,
      platform_cost_day = 1,
      platform_cost_level = 'group',
      platform_revshare_id = '',
      platform_revshare_type = '',
      platform_revshare_amount = 0,
    } = {},
    onUpdate,
  } = props;

  const {
    state: {
      paymentTypes: { list: paymentTypeList, loading: paymentTypeLoading } = {},
    },
    dispatch: globalDispatch,
  } = useContext(Context);

  const { partner_id, group_id, region_id, location_id } = levelData;
  const initPlatformCostData = {
    platform_cost_enabled: platform_cost_enabled,
    platform_cost_amount: Number((platform_cost_amount || 0) / 100),
    platform_cost_day: platform_cost_day,
    platform_cost_level: platform_cost_level,
    platform_revshare_enabled: Boolean(platform_revshare_id),
    platform_revshare_id: platform_revshare_id,
    platform_revshare_type: platform_revshare_type,
    platform_revshare_amount: Number((platform_revshare_amount || 0) / 100),
  };

  const [providerPaymentTypes, setProviderPaymentTypes] = useState(
    provider_payment_types,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [platformCostData, setPlatformCostData] =
    useState(initPlatformCostData);
  const [revLoading, setRevLoading] = useState(false);
  const [revshareList, setRevshareList] = useState([]);

  const classes = useStyles();

  const checkIfEqual = (updatedArray, initArray) => {
    const sortedUpdatedArray = updatedArray.slice().sort();
    const sortedInitArray = initArray.slice().sort();

    return !_.isEqual(sortedUpdatedArray, sortedInitArray);
  };

  const paymentTypeUpdated = checkIfEqual(
    providerPaymentTypes,
    provider_payment_types,
  );

  const isPlatformCostDataUpdated = !_.isEqual(
    platformCostData,
    initPlatformCostData,
  );

  const fetchRevshareList = async () => {
    if (revLoading) return;

    setRevLoading(true);

    try {
      let response = await REVSHARE_SERVICES.fetchRevshareByQueryParams();

      if (Utils.checkIfSuccess(response)) {
        let rows = Utils.getValue(response?.data?.rows, []);

        setRevshareList(rows);
      } else {
        throw response;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRevLoading(false);
    }
  };

  useEffect(() => {
    if (!platformCostData?.platform_revshare_enabled) return;

    if (!platformCostData?.platform_revshare_id && revshareList.length)
      handleChange(
        'platform_revshare_id',
        revshareList?.[0]?.revshare_id,
      );
  }, [platformCostData?.platform_revshare_enabled]);

  useEffect(() => {
    !revshareList.length && fetchRevshareList();
  }, []);

  useEffect(() => {
    setProviderPaymentTypes(provider_payment_types);
  }, [provider_payment_types]);

  useEffect(() => {
    setPlatformCostData({
      platform_cost_enabled: platform_cost_enabled,
      platform_cost_amount: Number((platform_cost_amount || 0) / 100),
      platform_cost_day: platform_cost_day,
      platform_cost_level: platform_cost_level,
      platform_revshare_enabled: Boolean(platform_revshare_id),
      platform_revshare_id: platform_revshare_id,
      platform_revshare_type: platform_revshare_type,
      platform_revshare_amount: Number((platform_revshare_amount || 0) / 100),
    });
  }, [
    platform_cost_enabled,
    platform_cost_amount,
    platform_cost_day,
    platform_cost_level,
    platform_revshare_id,
    platform_revshare_type,
    platform_revshare_amount
  ]);

  const levelPayload = {
    [setting_level]: {
      location_id,
      region_id,
      group_id,
      partner_id,
    },
  };

  const saveSettingsData = async () => {
    setIsLoading(true);

    let settingsData = {};
    settingsData = {
      provider_payment_types: providerPaymentTypes,
      ...platformCostData,
      platform_cost_amount: platformCostData?.platform_cost_amount * 100,
    };

    if (
      platformCostData?.platform_cost_enabled &&
      platformCostData?.platform_revshare_enabled
    ) {
      settingsData.platform_revshare_amount =
        platformCostData?.platform_revshare_enabled
          ? platformCostData?.platform_revshare_amount * 100
          : undefined;
    } else {
      settingsData.platform_revshare_id = '';
      settingsData.platform_revshare_type = undefined;
      settingsData.platform_revshare_amount = 100;
    }

    settingsData.platform_revshare_enabled = undefined;

    const payload = { ...levelPayload, setting: settingsData };

    try {
      const response = await USER_SERVICES.updateLevels(payload, setting_level);

      if (response?.type === 'success') {
        dispatchGlobalNotification(
          'success',
          'Capability updated successfully',
        );

        onUpdate?.('Settings');
      } else {
        throw response?.message || response?.error;
      }
    } catch (error) {
      dispatchGlobalNotification(
        'error',
        error || 'Error updating the capability details',
      );
    }

    setIsLoading(false);
  };

  const handleSwitchChange = (evt) => {
    const { name, checked } = evt?.target || {};

    setPlatformCostData({ ...platformCostData, [name]: checked });
  };

  const handleChange = (name, value) => setPlatformCostData({ ...platformCostData, [name]: value });

  const dispatchGlobalNotification = (severity, message) =>
    globalDispatch({
      type: ADD_NOTIFICATION,
      payload: { notification: { severity, message } },
    });

  const handleChecked = (chipVal, type = 'add') => {
    let newValue = [];

    const settingPaymentTypes = _.cloneDeep(providerPaymentTypes);
    if (type === 'add') {
      newValue = [...settingPaymentTypes, chipVal?.name];
    } else {
      newValue = settingPaymentTypes.filter(
        (indPayment) => indPayment !== chipVal?.name,
      );
    }

    setProviderPaymentTypes(newValue);
  };

  return (
    <SectionWrapper title="Payment Capabilities">
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          className={clsx('d-flex justify-content-end', styles.actionContainer)}
        >
          {(paymentTypeUpdated || isPlatformCostDataUpdated) && (
            <>
              <Button
                size="medium"
                onClick={saveSettingsData}
                disabled={isLoading}
                data-testid="save-payment-changes-button"
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <SaveIcon color="secondary" />
                  )
                }
              >
                <Typography color="secondary">Save Changes</Typography>
              </Button>
              <Button
                size="medium"
                className="mg_left_8"
                onClick={() => {
                  setProviderPaymentTypes(provider_payment_types);
                  setPlatformCostData(initPlatformCostData);
                }}
                disabled={isLoading}
                data-testid="cancel-payment-changes-button"
                startIcon={
                  <HighlightOffIcon
                    disabled={isLoading}
                    className="theme_color_orange"
                  />
                }
              >
                <Typography className="theme_color_orange">Cancel</Typography>
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={2} className={styles.paymentModeTitle}>
              <Typography variant="body1">Payment Mode</Typography>
            </Grid>
            {!paymentTypeLoading && (
              <Grid item xs={10} className="d-flex align-center">
                {paymentTypeList.map((indPayment) => {
                  const isSelected = providerPaymentTypes.find(
                    (pType) => pType === indPayment?.name,
                  );

                  return (
                    <React.Fragment key={indPayment?.name}>
                      <CheckBox
                        key={indPayment}
                        checked={isSelected === indPayment?.name}
                        value={indPayment?.name}
                        disabled={!indPayment?.optional}
                        onChange={() =>
                          handleChecked(
                            indPayment,
                            isSelected ? 'delete' : 'add',
                          )
                        }
                      />
                      <Typography variant="body1" className="mr-30">
                        {PAYMENT_TYPES[indPayment?.display] ||
                          indPayment?.display}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-10">
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              className={styles.toggleTitle}
            >
              <Typography variant="body1">Platform Cost</Typography>
            </Grid>
            <Grid item lg={1}>
              <ToggleTickSwitch
                className="mg_left_8 d-flex align-center"
                checked={platformCostData?.platform_cost_enabled}
                onChange={(e) => handleSwitchChange(e)}
                name="platform_cost_enabled"
                inputProps={{
                  'aria-label': 'Platform cost enable checkbox',
                  'data-testid': 'toggle-platform-cost',
                }}
              />
            </Grid>
            {platformCostData?.platform_cost_enabled && (
              <>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    variant="outlined"
                    label="Charged Amount"
                    name="platform_cost_amount"
                    value={Number(platformCostData?.platform_cost_amount)}
                    onChange={(e) => {
                      let value = Number(e?.target?.value);
                      value <= 0 && (value = 1);

                      handleChange(e?.target?.name, Number(value));
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: {
                        min: 1,
                        'data-testid': 'platform-cost-amount-input',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                  <FormControl fullWidth required variant="outlined">
                    <InputLabel>Charge Date</InputLabel>
                    <Select
                      id="platform-cost-charge-date"
                      label="Charge Date*"
                      name="platform_cost_day"
                      value={platformCostData?.platform_cost_day}
                      onChange={(e) =>
                        handleChange(e?.target?.name, Number(e?.target?.value))
                      }
                    >
                      {CHARGE_DATE.map((elem, i) => (
                        <MenuItem
                          key={elem?.key}
                          value={elem?.key}
                          data-testid={`platform-cost-charge-date-${elem?.key}`}
                        >
                          {elem?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className="mr-30">
                  <FormControl fullWidth required variant="outlined">
                    <InputLabel>Charge Level</InputLabel>
                    <Select
                      id="platform-cost-charge-level"
                      label="Charge Level*"
                      name="platform_cost_level"
                      value={platformCostData?.platform_cost_level}
                      onChange={(e) =>
                        handleChange(e?.target?.name, e?.target?.value)
                      }
                    >
                      {CHARGE_LEVEL.map((elm, i) => (
                        <MenuItem
                          key={elm?.key}
                          value={elm?.key}
                          data-testid={`platform-cost-charge-level-${elm?.key}`}
                        >
                          {elm?.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* Revshare support */}
        {platformCostData?.platform_cost_enabled && (
          <Grid item xs={12} className="mt-10">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                className={clsx(styles.toggleTitle, 'd-flex align-center')}
              >
                <Typography variant="body1">Revenue Share Enabled</Typography>
              </Grid>
              <Grid item xs={1} className="d-flex align-center ">
                <ToggleTickSwitch
                  className="mg_left_8 d-flex align-center"
                  checked={platformCostData?.platform_revshare_enabled}
                  onChange={(e) => handleSwitchChange(e)}
                  name="platform_revshare_enabled"
                  inputProps={{
                    'aria-label': 'platform cost - revshare enable checkbox',
                    'data-testid': 'toggle-platform-revshare',
                  }}
                />
              </Grid>

              {platformCostData?.platform_revshare_enabled && (
                <>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth required variant="outlined">
                      <InputLabel>Revshare Name</InputLabel>
                      <Select
                        id="platform-revshare-id"
                        label="Revshare Name*"
                        name="platform_revshare_id"
                        value={platformCostData?.platform_revshare_id}
                        onChange={(e) =>
                          handleChange(e?.target?.name, e?.target?.value)
                        }
                      >
                        {revshareList?.map((vendor, i) => (
                          <MenuItem
                            value={vendor?.revshare_id}
                            key={vendor?.revshare_id}
                            data-testid={`platform-revshare-select-item-${i}`}
                          >
                            {vendor?.revshare_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Revshare Amount"
                      name="platform_revshare_amount"
                      value={Number(platformCostData?.platform_revshare_amount)}
                      onChange={(e) => {
                        let value = Number(e?.target?.value);
                        (value <= 0 || isNaN(value)) && (value = 1);

                        handleChange(e?.target?.name, value);
                      }}
                      InputProps={{
                        inputProps: {
                          'data-testid': 'platform-revshare-amount-input',
                        },
                        endAdornment: (
                          <Grid container className={classes.tabsContainer}>
                            <Tabs
                              value={platformCostData?.platform_revshare_type}
                              indicatorColor="secondary"
                              textColor="secondary"
                              onChange={(_evt, value) =>
                                handleChange('platform_revshare_type', value)
                              }
                              className={classes.discountTabs}
                            >
                              {PAYOUT_TYPES.map((indType) => (
                                <Tab
                                  key={indType?.value}
                                  value={indType?.value}
                                  label={indType?.label}
                                />
                              ))}
                            </Tabs>
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </SectionWrapper>
  );
};

PaymentCapabilities.propTypes = {
  levelData: PropTypes.object,
  settings: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default PaymentCapabilities;
