import React from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

import styles from "./DividerWithText.module.scss";

const DividerWithText = (props) => {
  const {
    text = "",
    align = "center",
    spacing = "1",
  } = props;

  const getAlignmentClassName = () => {
    switch (align) {
      case 'left':
        return styles.alignLeft;
      case 'right':
        return styles.alignRight;
      default:
        return styles.alignCenter;
    }
  }; 

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={clsx(styles.dividerContainer, styles[`spacing-${spacing}`])}>
        {text && (
          <Typography component="span" className={clsx(getAlignmentClassName(), styles.dividerText)}>
            {text}
          </Typography>
        )}
        <Typography component="div" className={styles.divider}></Typography>
      </Grid>
    </Grid>
  );
};

DividerWithText.propTypes = {
  text: PropTypes.string,
  align: PropTypes.string,
  spacing: PropTypes.string,
};

export default DividerWithText;
