import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { CircularProgress, Box } from '@material-ui/core';

const IframeLoader = ({ src, title }) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const containerRef = useRef(null);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    console.error('Failed to load content.');
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClickOutside = (event) => {
    if (
      containerRef?.current &&
      !containerRef?.current.contains(event?.target)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document?.addEventListener('mousedown', handleClickOutside);
    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!show) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      bgcolor="rgba(0, 0, 0, 0.7)"
      zIndex={1300}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        ref={containerRef}
        position="relative"
        width="80%"
        height="80%"
        bgcolor="white"
        boxShadow={3}
        borderRadius={8}
        overflow="hidden"
      >
        {/* Loading Spinner */}
        {loading && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{ transform: 'translate(-50%, -50%)', zIndex: 1 }}
          >
            <CircularProgress />
          </Box>
        )}

        <iframe
          src={src}
          title={title}
          width="100%"
          height="100%"
          tabIndex="-1" 
          onLoad={handleLoad}
          onError={handleError}
          style={{ display: loading ? 'none' : 'block' }}
        />
      </Box>
    </Box>
  );
};

IframeLoader.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string
};

export default IframeLoader;
